<template>
  <div class="result" :class="{ 'result-tile': mode === 'tiles' }">
    <div class="result-panel-left">
      <input v-if="showCheckboxes" type="checkbox" class="result-checkbox" :value="publication.id" v-model="checkValue" @click.stop>
      <div class="result-cover-container">
        <publication-cover :url="publication.cover" ref="coverImg"></publication-cover>
      </div>
      <div>
        <router-link
          v-if="publication.pubkey && publication.licenseavailable"
          :to="{ name: 'publicationloader', params: { pubKey: publication.pubkey } }"
          class="input-button input-button-outlined"
          style="width: 100%; margin: 4px 0;"
        >{{ capitalize($t('read')) }}</router-link>
        <button
          v-else-if="publication.pubkey && !publication.licenseavailable"
          class="input-button input-button-outlined"
          style="width: 100%; margin: 4px 0;"
          disabled
        >{{ capitalize($t('in use')) }}</button>
        <button
          v-if="showRequestButton"
          class="input-button input-button-outlined"
          style="width: 100%; margin: 4px 0;"
          @click.stop="openModalRequest"
        >{{ capitalize($t('request')) }}</button>
      </div>
    </div>
    <div class="result-panel-right">
      <div v-if="publication.authors?.length" class="result-authors" :title="publication.authors.join('; ')">
        {{ publication.authors.join('; ') }}
      </div>
      <div v-if="publication.title" class="result-title">
        {{ publication.title }}
      </div>
      <div v-if="publication.subtitle" class="result-subtitle">
        {{ publication.subtitle }}
      </div>
      <div v-if="publication.documenttype" class="result-documenttype">
        <img v-if="publication.documenttypeicon" class="result-documenttypeicon" :src="publication.documenttypeicon" />
        {{ publication.documenttype }}
      </div>
      <div v-if="publication.abstract" class="result-abstract">
        {{ publication.abstract }}
      </div>
      <div v-if="publishingDisplay.length" class="result-publishing">
        {{ publishingDisplay }}
      </div>
      <div v-if="publication.keywords?.length" class="result-keywords">
        {{ publication.keywords.join(', ') }}
      </div>
      <div v-if="hasMultimedia" class="result-multimedia">
        <div v-if="publication.multimedia.links?.length" class="result-multimedia-links">
          <publication-multimedia
            v-for="link of publication.multimedia.links"
            :key="link.url"
            :mediatype="MultimediaTypes.Link"
            :url="link.url"
            :display="link.label ? link.label : link.url"
          />
        </div>
        <div v-if="publication.multimedia.documents?.length" class="result-multimedia-documents">
          <publication-multimedia
            v-for="document of publication.multimedia.documents"
            :key="document"
            :mediatype="MultimediaTypes.Document"
            :url="document"
          />
        </div>
        <div v-if="publication.multimedia.sounds?.length" class="result-multimedia-sounds">
          <publication-multimedia
            v-for="sound of publication.multimedia.sounds"
            :key="sound"
            :mediatype="MultimediaTypes.Sound"
            :url="sound"
          />
        </div>
        <div v-if="publication.multimedia.movies?.length" class="result-multimedia-movies">
          <publication-multimedia
            v-for="movie of publication.multimedia.movies"
            :key="movie"
            :mediatype="MultimediaTypes.Movie"
            :url="movie"
          />
        </div>
        <div v-if="publication.multimedia.images?.length" class="result-multimedia-images">
          <publication-multimedia
            :mediatype="MultimediaTypes.ImageGallery"
            :images="publication.multimedia.images"
          />
        </div>
        <div v-if="publication.multimedia.copyDocuments?.length && clientSettings.showCopyDocuments" class="result-multimedia-copydocuments">
          <publication-multimedia
            v-for="copyDocument of publication.multimedia.copyDocuments.slice(0, maxVisibleCopyDocuments)"
            :key="copyDocument"
            :mediatype="MultimediaTypes.Document"
            :url="copyDocument"
          />
          <div v-if="publication.multimedia.copyDocuments.length > maxVisibleCopyDocuments">
            + {{ publication.multimedia.copyDocuments.length - maxVisibleCopyDocuments }} {{$t('moreCopyDocuments')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicationCover from '@/components/publication/PublicationCover'
import PublicationMultimedia, { MultimediaTypes } from '@/components/publication/PublicationMultimedia'
import { Events } from '@/events'

export default {
  name: 'module-result',
  props: {
    publication: Object,
    moduleId: String,
    mode: String,
    showCheckboxes: {
      type: Boolean,
      default: () => false
    },
    check: Array
  },
  emits: ['update:check'],
  computed: {
    publishingDisplay () {
      const fields = []

      if (this.publication.publisher) fields.push(this.publication.publisher)
      if (this.publication.yearofpublication) fields.push(this.publication.yearofpublication)
      if (this.publication.edition) fields.push(`(${this.publication.edition})`)

      return fields.join(' ')
    },
    hasMultimedia () {
      if (!this.publication.multimedia) return false

      return (
        this.publication.multimedia.documents?.length ||
        this.publication.multimedia.images?.length ||
        this.publication.multimedia.sounds?.length ||
        this.publication.multimedia.movies?.length ||
        this.publication.multimedia.links?.length ||
        (this.publication.multimedia.copyDocuments?.length && this.clientSettings.showCopyDocuments)
      )
    },
    showRequestButton () {
      const client = this.$store.getters.getClient
      const module = this.$store.getters['searchStore/searchModules/getModule'](this.moduleId)
      return module.module === '2' && client.email
    },
    clientSettings () {
      return this.$store.getters['settings/getClientSettings']
    },
    checkValue: {
      get () {
        return this.check
      },
      set (value) {
        this.$emit('update:check', value)
      }
    }
  },
  data: () => ({
    MultimediaTypes,
    maxVisibleCopyDocuments: 5
  }),
  methods: {
    openModalRequest () {
      Events.$emit('openModalRequest', { publication: this.publication.id, module: this.moduleId })
    }
  },
  components: {
    'publication-cover': PublicationCover,
    'publication-multimedia': PublicationMultimedia
  },
  mounted () {
    if (this.$refs.coverImg) this.$refs.coverImg.loadImage()
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .result {
    display: flex;
    flex-direction: row;
    padding: 20px;

    .result-panel-left {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;
      width: 102px;
    }

    .result-panel-right {
      width: 100%;
      padding-left: 20px;
      overflow: hidden;
    }
  }

  .result-authors {
    font-style: italic;
    margin-bottom: 4px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .result-title {
    font-weight: bold;
    font-size: ($font_size + 4);
  }

  .result-documenttype {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2px;
    font-size: ($font_size - 2);

    .result-documenttypeicon {
      max-height: 20px;
      margin-right: 4px;
    }
  }

  .result-abstract {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 4.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 20px;
  }

  .result-publishing {
    margin-top: 20px;
  }

  .result-keywords {
    margin-top: 4px;
    font-style: italic;
    font-size: ($font_size - 2);
  }

  .result-multimedia {
    margin-top: 20px;

    [class^="result-multimedia-"] + .result-multimedia-copydocuments::before {
      content: "";
      border-top: 1px solid #ccc;
      display: block;
      width: 300px;
      margin: 6px 0;
    }
  }

  .result-cover-container {
    width: 100px;
    height: 150px;
    margin-bottom: 10px;
  }

  .result.result-tile {
    .result-multimedia {
      display: none;
    }
  }

  .result-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    z-index: 1;
  }
</style>
