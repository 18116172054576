export default {
  "common.excluding-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excl. tax"])},
  "header": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close document"])},
    "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin area"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "Log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "Try again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
  "Version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
  "Loading reader...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading reader..."])},
  "Loading publication...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading publication..."])},
  "Loan period expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loan period expired"])},
  "The loan period for this document has expired. Choose \"request extention\" to request an extention, or \"close\" to close this document.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The loan period for this document has expired. Choose \"request extention\" to request an extention, or \"close\" to close this document."])},
  "The loan period is about to expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The loan period is about to expire"])},
  "The loan period is about to expire due to inactivity. Do you want to continue using this document or do you want to close it?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The loan period is about to expire due to inactivity. Do you want to continue using this document or do you want to close it?"])},
  "Continue using this document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue using this document"])},
  "Request extention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request extention"])},
  "Close document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close document"])},
  "Read online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read online"])},
  "In use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In use"])},
  "Filter results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter results"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "Less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less"])},
  "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords"])},
  "authors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authors"])},
  "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishers"])},
  "jurisdiction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurisdictions"])},
  "yearofpublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year of publication"])},
  "Authors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authors"])},
  "Author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Author"])},
  "ISBN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
  "Publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisher"])},
  "Edition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition"])},
  "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "Jurisdiction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jurisdiction"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "Text size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text size"])},
  "Zoom in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoom in"])},
  "Zoom out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoom out"])},
  "Automatic zoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic zoom"])},
  "Navigate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate"])},
  "Navigate to the first page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to the first page"])},
  "Navigate to the last page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to the last page"])},
  "Navigate to the previous chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to the previous chapter"])},
  "Navigate to the next chapter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to the next chapter"])},
  "Navigate to the previous page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to the previous page"])},
  "Navigate to the next page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigate to the next page"])},
  "Searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching"])},
  "Search in this document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search in this document"])},
  "Current query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current query"])},
  "There are no results for this query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no results for this query"])},
  "Stop search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop search"])},
  "Use the searchbar to start searching in this document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the searchbar to start searching in this document"])},
  "Search for title, author or text in a document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for title, author or text in a document"])},
  "Search Result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search result"])},
  "result found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["result found"])},
  "results found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results found"])},
  "Print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
  "Print from the current page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print from the current page"])},
  "Amount of pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of pages"])},
  "Preparing print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing print"])},
  "Search in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search in"])},
  "Metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata"])},
  "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TekTextst"])},
  "Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both"])},
  "Search for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for"])},
  "searchbar.for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On"])},
  "Seperate words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seperate words"])},
  "Exact sentence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exact sentence"])},
  "License key expired/not known": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License key expired/not known"])},
  "Server could not be reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server could not be reached"])},
  "There was a problem establishing a connection to the server. Please try again.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a problem establishing a connection to the server. Please try again."])},
  "Could not get page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not get page"])},
  "Looks like the page you are trying to load doesn't exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks like the page you are trying to load doesn't exist"])},
  "No appearances found for this publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No appearances found for this publication"])},
  "There are no appearances linked to this publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no appearances linked to this publication"])},
  "Multiple appearances found for this publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple appearances found for this publication"])},
  "There are multiple appearances found for this publication.<br>Please choose which appearance you would like to read:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are multiple appearances found for this publication.<br>Please choose which appearance you would like to read:"])},
  "Publication not be found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication not be found"])},
  "Document not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document not found"])},
  "The requested publication could not be found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested publication could not be found"])},
  "Metadata not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata not found"])},
  "The metadata could not be found for this document. This data is needed to initialize the reader.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The metadata could not be found for this document. This data is needed to initialize the reader."])},
  "This document could not be opened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This document could not be opened"])},
  "This document is already in use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This document is already in use"])},
  "This document is already in use by a colleague. Try again later.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This document is already in use by a colleague. Try again later."])},
  "This document is currently opened by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This document is currently opened by"])},
  "Please try again when one of the open documents is closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again when one of the open documents is closed"])},
  "Could not get the page for printing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not get the page for printing"])},
  "Looks like the page you are trying to print doesn't exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks like the page you are trying to print doesn't exist"])},
  "Authentication could not be refreshed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication could not be refreshed"])},
  "The authentication with the server has expired and could not be refreshed. Please log in again to continue using the application.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication with the server has expired and could not be refreshed. Please log in again to continue using the application."])},
  "An error occurred in our server while searching in the catalogue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred in our server while searching in the catalogue"])},
  "Please try again later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again later"])},
  "Could not switch client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["could not switch environment"])},
  "Something went wrong while switching client, please try again later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong while switching environment, please try again later"])},
  "Something went wrong while sending the request, pleasy try again later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong while sending the request, pleasy try again later"])},
  "You will automatically be redirected for authentication.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will automatically be redirected for authentication."])},
  "Click here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here"])},
  "to navigate to the authentication page if automatically redirecting is taking a long time.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to navigate to the authentication page if automatically redirecting is taking a long time."])},
  "A problem occurred while authenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A problem occurred while authenticating"])},
  "Token missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing key"])},
  "Please try again later or contact an administrator if the problem keeps occurring.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the problem persists, please try again later or contact an administrator"])},
  "varReaderSearchResultsPages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There are ", _interpolate(_named("maxIndex")), " pages with hits"])},
  "varReaderSearchResults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Page ", _interpolate(_named("currentIndex")), " of ", _interpolate(_named("maxIndex"))])},
  "Shopping basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping cart"])},
  "There are no items in your shopping basket.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is nothing in your shopping cart."])},
  "Checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "Back to shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to the shop"])},
  "item.new.tooltip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This publication is added less than ", _interpolate(_named("daysMarkedAsNew")), " days ago."])},
  "shop": {
    "library": {
      "search": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for title or ISBN"])}
      },
      "items": {
        "add-to-basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to basket"])},
        "already-in-basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already in basket"])}
      }
    },
    "basket": {
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
    },
    "checkout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
      "complete-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete order"])},
      "order-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order has failed. Please try again at a later time."])},
      "validation": {
        "accept-terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please accept both agreements"])}
      },
      "privacy-agreements": {
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["I have read and agree to the ", _interpolate(_list(0)), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy agreements"])}
      },
      "general-agreements": {
        "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["I have read and agree to the ", _interpolate(_list(0)), "."])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general agreements"])}
      }
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order succesfully sent"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your order! You will receive a confirmation of receipt by E-mail as soon as possible, and we will contact you again, once the ordered books are available to you in XPOSI."])}
    }
  },
  "searchOption": {
    "searchMode": {
      "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simple"])},
      "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced"])}
    },
    "searchContext": {
      "metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metadata"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["content"])},
      "metadataAndContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metadata and content"])}
    },
    "fieldRelation": {
      "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
      "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])}
    },
    "fieldMode": {
      "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contains"])},
      "exact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exact"])},
      "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not"])}
    }
  },
  "sorting": {
    "order": {
      "descending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descending (Z-A)"])},
      "ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ascending (A-Z)"])}
    },
    "fields": {
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relevance"])}
    }
  },
  "metadataField": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["title"])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["author"])},
    "authors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["authors"])},
    "corporateauthor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corporate author"])},
    "corporateauthors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corp. authors"])},
    "yearofpublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year of publication"])},
    "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
    "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["publisher"])},
    "documenttype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["document type"])},
    "seriestitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["series"])},
    "seriesnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seriesnumber"])},
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reference"])},
    "placeofpublication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["place of publication"])},
    "edition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edition"])},
    "annotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annotation"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])},
    "filenumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dossiernummer"])},
    "filedatestart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date start"])},
    "filedateend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date end"])},
    "filedatedestroyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date destroyed"])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["source"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["class"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["classification"])},
    "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keywords"])},
    "geographicalkeywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geographical keywords"])},
    "registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registry number"])},
    "collation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collation"])},
    "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["library"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location"])},
    "loanstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loanability"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
    "part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["part"])},
    "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscription"])},
    "abstract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abstract"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shelf mark"])},
    "bibiscode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIBIScode"])},
    "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["document"])},
    "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collection"])},
    "additionalinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additional information"])},
    "parenttitles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parent titles"])},
    "childtitles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["childtitles"])},
    "rubric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rubric"])},
    "nuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuance"])},
    "extranuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extra nuance"])}
  },
  "facets": {
    "sorttype": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["sorting on ", _interpolate(_named("currentType")), ", click to sort on ", _interpolate(_named("alternativeType"))])},
    "sortorder": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["sorting on ", _interpolate(_named("currentOrder")), " order, click to sort on ", _interpolate(_named("alternativeOrder")), " order"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["value"])},
    "hits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hits"])},
    "ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ascending"])},
    "descending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descending"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apply filters"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove all filters"])},
    "cancelChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel filter changes"])},
    "freeFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["custom filters"])}
  },
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])},
  "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["advanced"])},
  "sort by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sort by"])},
  "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in"])},
  "add filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add filter"])},
  "remove filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove filter"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
  "all results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all results"])},
  "show all {modulename} results": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["show all ", _interpolate(_named("modulename")), " results"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reset"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close"])},
  "load additional results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["load additional results"])},
  "loading additional results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading additional results"])},
  "all results have been loaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all results have been loaded"])},
  "there are no results for this query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there are no results for this query"])},
  "there has been an error while searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there has been an error while searching"])},
  "searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["searching"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more"])},
  "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["less"])},
  "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["read"])},
  "in use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in use"])},
  "characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["characteristics"])},
  "abstract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abstract"])},
  "multimedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["multimedia"])},
  "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["publication"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archive"])},
  "other characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other characteristics"])},
  "title relations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["title relations"])},
  "copies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copies"])},
  "moreCopyDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copy document(s)"])},
  "loanable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loanable"])},
  "not loanable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not loanable"])},
  "loaned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loaned"])},
  "new acquisitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new acquisitions"])},
  "there was a problem loading the acquisitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there was a problem loading the acquisitions"])},
  "there are no new acquisitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there are no new acquisitions"])},
  "There are filled advanced fields. Click to open the advanced search panel.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are filled advanced fields. Click to open the advanced search panel."])},
  "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["request"])},
  "email_request": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email address"])},
    "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["department"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["message"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send to library"])},
    "is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is required"])},
    "is_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is invalid"])}
  },
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["environment"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])},
  "languages": {
    "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])}
  },
  "placeholders": {
    "rich_text_editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type a text here..."])}
  },
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
  "select_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select image"])},
  "error_image_wrong_type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This filetype is not supported, try one of the following filetypes: ", _interpolate(_named("exampleFileTypes"))])},
  "error_image_no_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No file selected"])},
  "admin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["admin Area"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logo"])},
    "changeLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change logo"])},
    "deleteLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove current logo"])},
    "informationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["information text"])},
    "showInformationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show information text"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])},
    "searchResultList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results list"])},
    "showCopyDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show documents related to copies in resultlist"])},
    "defaultLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["default language"])},
    "searchDefaults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search defaults"])},
    "searchContext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["context"])},
    "resultSorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sorting"])}
  },
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["share"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copy"])},
  "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["options"])},
  "resultlistmode": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultview"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["list"])},
    "tiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiles"])}
  },
  "actions": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])}
  },
  "bookshelf": {
    "bookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bookshelf"])},
    "bookshelves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bookshelves"])},
    "myBookshelves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my bookshelves"])},
    "externalBookshelves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["external bookshelves"])},
    "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["there are no bookshelves yet"])},
    "addBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add bookshelf"])},
    "editBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit bookshelf"])},
    "deleteBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete bookshelf"])},
    "bookshelfName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bookshelf name"])},
    "is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is required"])},
    "bookshelfLoadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the requested bookshelf can not be loaded"])},
    "deleteBookshelfConfirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["are you sure you want to delete [ ", _interpolate(_named("bookshelfName")), " ] ?"])},
    "addToBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add to bookshelf"])},
    "emptyContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this bookshelf is empty"])},
    "addItemTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add item to"])},
    "newBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new bookshelf"])},
    "existingBookshelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["existing bookshelf"])}
  },
  "selectedResult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " selected result"])},
  "selectedResults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " selected results"])}
}