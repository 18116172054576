<template>
  <div class="pubmeta">

    <div class="section-top">
      <div class="top-cover">
        <publication-cover :url="metadata.cover" ref="coverImg"></publication-cover>
      </div>
      <div class="top-data">
        <div v-if="metadata.title" class="metadata-title">{{ metadata.title }}</div>
        <div v-if="metadata.subtitle" class="metadata-subtitle">{{ metadata.subtitle }}</div>
      </div>
    </div>

    <div class="section">
      <router-link
        v-if="metadata.pubkey && metadata.licenseavailable"
        :to="{ name: 'publicationloader', params: { pubKey: metadata.pubkey } }"
        class="input-button input-button-outlined"
        style="width: 100%; margin: 4px 0;"
      >{{ capitalize($t('read')) }}</router-link>
      <button
        v-else-if="metadata.pubkey && !metadata.licenseavailable"
        class="input-button input-button-outlined"
        style="width: 100%; margin: 4px 0;"
        disabled
      >{{ capitalize($t('in use')) }}</button>
      <button
        v-if="showRequestButton"
        class="input-button input-button-outlined"
        style="width: 100%; margin: 4px 0;"
        @click.stop="openModalRequest"
      >{{ capitalize($t('request')) }}</button>
    </div>

    <div v-if="hasCharacteristics" class="section">
      <div class="section-header"><span>{{ capitalize($t('characteristics')) }}</span></div>
      <div v-if="metadata.documenttype" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.documenttype')) }}</div>
        <div class="section-row-data metadata-documenttype">
          <img v-if="metadata.documenttypeicon" class="result-documenttypeicon" :src="metadata.documenttypeicon" />
          <searchable :value="metadata.documenttype" :field="'documenttype'" />
        </div>
      </div>
      <div v-if="metadata.authors?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.author')) }}</div>
        <div class="section-row-data">
          <div v-for="(author, index) of metadata.authors" :key="author">
            <searchable :value="author" :field="'authors'" />{{ (index === metadata.authors.length - 1) ? '' : ';' }}
          </div>
        </div>
      </div>
      <div v-if="metadata.corporateauthors?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.corporateauthor')) }}</div>
        <div class="section-row-data">
          <div v-for="(corporateAuthor, index) of metadata.corporateauthors" :key="corporateAuthor">
            <searchable :value="corporateAuthor" :field="'corporateauthors'" />{{ (index === metadata.corporateauthors.length - 1) ? '' : ';' }}
          </div>
        </div>
      </div>
      <div v-if="metadata.isbn" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.isbn')) }}</div>
        <div class="section-row-data">{{ metadata.isbn }}</div>
      </div>
      <div v-if="metadata.seriestitle" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.seriestitle')) }}</div>
        <div class="section-row-data"><searchable :value="metadata.seriestitle" :field="'seriestitle'" /></div>
      </div>
      <div v-if="metadata.seriesnumber" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.seriesnumber')) }}</div>
        <div class="section-row-data">{{ metadata.seriesnumber }}</div>
      </div>
      <div v-if="metadata.bibiscode" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.bibiscode')) }}</div>
        <div class="section-row-data">{{ metadata.bibiscode }}</div>
      </div>
      <div v-if="metadata.reference" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.reference')) }}</div>
        <div class="section-row-data" v-html="metadata.reference"></div>
      </div>
      <div v-if="metadata.signature" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.signature')) }}</div>
        <div class="section-row-data" v-html="metadata.signature"></div>
      </div>
    </div>

    <div v-if="hasAbstract" class="section">
      <div class="section-header"><span>{{ capitalize($t('abstract')) }}</span></div>
      <div
        class="metadata-abstract"
        :class="{
          expandable: expandableAbstract,
          expanded: expandableAbstract && abstractExpanded,
          collapsed: expandableAbstract && !abstractExpanded
        }"
        ref="abstractContent"
      >{{ metadata.abstract }}</div>
      <div v-if="expandableAbstract" class="abstract-toggle" @click="toggleAbstract">
        <div class="abstract-toggle-indicator" :class="[abstractExpanded ? 'up' : 'down']"></div>
      </div>
    </div>

    <div v-if="hasMultimedia" class="section">
      <div class="section-header"><span>{{ capitalize($t('multimedia')) }}</span></div>
      <div v-if="metadata.multimedia.links?.length" class="metadata-multimedia-links">
        <publication-multimedia
          v-for="link of metadata.multimedia.links"
          :key="link.url"
          :mediatype="MultimediaTypes.Link"
          :url="link.url"
          :display="link.label ? link.label : link.url"
        />
      </div>
      <div v-if="metadata.multimedia.documents?.length" class="metadata-multimedia-documents">
        <publication-multimedia
          v-for="document of metadata.multimedia.documents"
          :key="document"
          :mediatype="MultimediaTypes.Document"
          :url="document"
        />
      </div>
      <div v-if="metadata.multimedia.sounds?.length" class="metadata-multimedia-sounds">
        <publication-multimedia
          v-for="sound of metadata.multimedia.sounds"
          :key="sound"
          :mediatype="MultimediaTypes.Sound"
          :url="sound"
        />
      </div>
      <div v-if="metadata.multimedia.movies?.length" class="metadata-multimedia-movies">
        <publication-multimedia
          v-for="movie of metadata.multimedia.movies"
          :key="movie"
          :mediatype="MultimediaTypes.Movie"
          :url="movie"
        />
      </div>
      <div v-if="metadata.multimedia.images?.length" class="metadata-multimedia-images">
        <publication-multimedia
          :mediatype="MultimediaTypes.ImageGallery"
          :images="metadata.multimedia.images"
        />
      </div>
    </div>

    <div v-if="hasPublication" class="section">
      <div class="section-header"><span>{{ capitalize($t('publication')) }}</span></div>
      <div v-if="metadata.publisher" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.publisher')) }}</div>
        <div class="section-row-data"><searchable :value="metadata.publisher" :field="'publisher'" /></div>
      </div>
      <div v-if="metadata.placeofpublication" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.placeofpublication')) }}</div>
        <div class="section-row-data">{{ metadata.placeofpublication }}</div>
      </div>
      <div v-if="metadata.yearofpublication" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.yearofpublication')) }}</div>
        <div class="section-row-data">{{ metadata.yearofpublication }}</div>
      </div>
      <div v-if="metadata.edition" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.edition')) }}</div>
        <div class="section-row-data">{{ metadata.edition }}</div>
      </div>
      <div v-if="metadata.annotation?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.annotation')) }}</div>
        <div class="section-row-data">
          <div v-for="value in metadata.annotation" :key="value" class="metadata-repeatable">{{ value }}</div>
        </div>
      </div>
      <div v-if="metadata.language" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.language')) }}</div>
        <div class="section-row-data">{{ metadata.language }}</div>
      </div>
    </div>

    <div v-if="hasArchive" class="section">
      <div class="section-header"><span>{{ capitalize($t('archive')) }}</span></div>
      <div v-if="metadata.filenumber" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.filenumber')) }}</div>
        <div class="section-row-data">{{ metadata.filenumber }}</div>
      </div>
      <div v-if="metadata.filedatestart" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.filedatestart')) }}</div>
        <div class="section-row-data">{{ metadata.filedatestart }}</div>
      </div>
      <div v-if="metadata.filedateend" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.filedateend')) }}</div>
        <div class="section-row-data">{{ metadata.filedateend }}</div>
      </div>
      <div v-if="metadata.filedatedestroyed" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.filedatedestroyed')) }}</div>
        <div class="section-row-data">{{ metadata.filedatedestroyed }}</div>
      </div>
    </div>

    <div v-if="hasOtherCharacteristics" class="section">
      <div class="section-header"><span>{{ capitalize($t('other characteristics')) }}</span></div>
      <div v-if="metadata.source" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.source')) }}</div>
        <div class="section-row-data">{{ metadata.source }}</div>
      </div>
      <div v-if="metadata.class?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.class')) }}</div>
        <div class="section-row-data">
          <div v-for="(classValue, index) of metadata.class" :key="classValue">
            <searchable :value="classValue" :field="'class'" />{{ (index === metadata.class.length - 1) ? '' : ';' }}
          </div>
        </div>
      </div>
      <div v-if="metadata.classification" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.classification')) }}</div>
        <div class="section-row-data">{{ metadata.classification }}</div>
      </div>
      <div v-if="metadata.keywords?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.keywords')) }}</div>
        <div class="section-row-data">
          <div v-for="(keyword, index) of metadata.keywords" :key="keyword">
            <searchable :value="keyword" :field="'keywords'" />{{ (index === metadata.keywords.length - 1) ? '' : ';' }}
          </div>
        </div>
      </div>
      <div v-if="metadata.geographicalkeywords?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.geographicalkeywords')) }}</div>
        <div class="section-row-data">
          <div v-for="(keyword, index) of metadata.geographicalkeywords" :key="keyword">{{ keyword }}{{ (index === metadata.geographicalkeywords.length - 1) ? '' : ';' }}</div>
        </div>
      </div>
      <div v-if="metadata.nuance?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.nuance')) }}</div>
        <div class="section-row-data">
          <div v-for="(nuance, index) of metadata.nuance" :key="nuance">
            <searchable :value="nuance" :field="'nuance'" />{{ (index === metadata.nuance.length - 1) ? '' : ';' }}
          </div>
        </div>
      </div>
      <div v-if="metadata.extranuance?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.extranuance')) }}</div>
        <div class="section-row-data">
          <div v-for="(extranuance, index) of metadata.extranuance" :key="extranuance">
            <searchable :value="extranuance" :field="'extranuance'" />{{ (index === metadata.extranuance.length - 1) ? '' : ';' }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasTitleRelations" class="section">
      <div class="section-header"><span>{{ capitalize($t('title relations')) }}</span></div>
      <div v-if="metadata.titlerelations.parents?.length">
        <h4>{{ capitalize($t('metadataField.parenttitles')) }}</h4>
        <div v-for="parent of metadata.titlerelations.parents" :key="parent.code" class="metadata-titlerelation">
          <div class="section-row">
            <div class="section-row-label">{{ capitalize($t('metadataField.title')) }}</div>
            <div class="section-row-data">
                {{ parent.title }}
            </div>
          </div>
          <div class="section-row">
            <div class="section-row-label">{{ capitalize($t('metadataField.bibiscode')) }}</div>
            <div class="section-row-data">
              <searchable :value="parent.code" :field="'bibiscode'" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="metadata.titlerelations.children?.length">
        <h4>{{ capitalize($t('metadataField.childtitles')) }}</h4>
        <div v-for="child of metadata.titlerelations.children" :key="child.code" class="metadata-titlerelation">
          <div class="section-row">
            <div class="section-row-label">{{ capitalize($t('metadataField.title')) }}</div>
            <div class="section-row-data">
                {{ child.title }}
            </div>
          </div>
          <div class="section-row">
            <div class="section-row-label">{{ capitalize($t('metadataField.bibiscode')) }}</div>
            <div class="section-row-data">
              <searchable :value="child.code" :field="'bibiscode'" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasCopies" class="section section-copies">
      <div class="section-header"><span>{{ capitalize($t('copies')) }}</span></div>
      <publication-copy
        v-for="copy of metadata.copies"
        :key="copy.year + copy.part + copy.subscription + copy.registry + copy.isbn"
        :copy="copy"
      ></publication-copy>
    </div>

  </div>
</template>

<script>
import PublicationCopy from '@/components/publication/PublicationCopy'
import PublicationCover from '@/components/publication/PublicationCover'
import PublicationSearchable from '@/components/publication/PublicationSearchable'
import PublicationMultimedia, { MultimediaTypes } from '@/components/publication/PublicationMultimedia'
import { Events } from '@/events'

const ABSTRACT_VISIBLE_LIMIT = 200

export default {
  name: 'publication-metadata',
  props: {
    metadata: {
      type: Object,
      default: () => ({})
    },
    moduleId: String
  },
  computed: {
    hasCharacteristics () {
      return (
        this.metadata.documenttype ||
        this.metadata.authors?.length ||
        this.metadata.corporateauthors?.length ||
        this.metadata.isbn ||
        this.metadata.seriestitle ||
        this.metadata.seriesnumber ||
        this.metadata.bibiscode ||
        this.metadata.reference ||
        this.metadata.signature
      )
    },
    hasAbstract () {
      return this.metadata.abstract
    },
    hasMultimedia () {
      if (!this.metadata.multimedia) return false

      return (
        this.metadata.multimedia.documents?.length ||
        this.metadata.multimedia.images?.length ||
        this.metadata.multimedia.sounds?.length ||
        this.metadata.multimedia.movies?.length ||
        this.metadata.multimedia.links?.length
      )
    },
    hasPublication () {
      return (
        this.metadata.publisher ||
        this.metadata.placeofpublication ||
        this.metadata.yearofpublication ||
        this.metadata.edition ||
        this.metadata.annotation?.length ||
        this.metadata.language
      )
    },
    hasArchive () {
      return (
        this.metadata.filenumber ||
        this.metadata.filedatestart ||
        this.metadata.filedateend ||
        this.metadata.filedatedestroyed
      )
    },
    hasOtherCharacteristics () {
      return (
        this.metadata.source ||
        this.metadata.class?.length ||
        this.metadata.classification ||
        this.metadata.keywords?.length ||
        this.metadata.geographicalkeywords?.length ||
        this.metadata.nuance?.length ||
        this.metadata.extranuance?.length
      )
    },
    hasTitleRelations () {
      return this.metadata.titlerelations && (
        this.metadata.titlerelations.children?.length ||
        this.metadata.titlerelations.parents?.length
      )
    },
    hasCopies () {
      return this.metadata.copies?.length
    },
    showRequestButton () {
      const client = this.$store.getters.getClient
      const module = this.$store.getters['searchStore/searchModules/getModule'](this.moduleId)
      return module.module === '2' && client.email
    }
  },
  data: () => ({
    expandableAbstract: false,
    abstractExpanded: false,
    MultimediaTypes
  }),
  methods: {
    toggleAbstract () {
      this.abstractExpanded = !this.abstractExpanded
    },
    openModalRequest () {
      Events.$emit('openModalRequest', { publication: this.metadata.id, module: this.moduleId })
    }
  },
  components: {
    'publication-copy': PublicationCopy,
    'publication-cover': PublicationCover,
    searchable: PublicationSearchable,
    'publication-multimedia': PublicationMultimedia
  },
  mounted () {
    if (this.$refs.coverImg) this.$refs.coverImg.loadImage()

    if (this.$refs.abstractContent) {
      const abstractHeight = this.$refs.abstractContent.offsetHeight
      if (abstractHeight > ABSTRACT_VISIBLE_LIMIT) this.expandableAbstract = true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .pubmeta {
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  .metadata-title {
    font-weight: bold;
    font-size: ($font_size + 4);
  }

  .metadata-documenttype {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      max-height: 20px;
      margin-right: 4px;
    }
  }

  .section-top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }

  .section-header {
    margin-bottom: 4px;

    span {
      font-weight: bold;
      font-size: ($font_size + 4);
      background-color: white;
      padding-right: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      border-bottom: 1px solid #ccc;
    }
  }

  .section-row {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    margin: 4px 0;

    .section-row-label {
      width: 140px;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .section-row-data {
      width: 100%;
    }
  }

  .top-data {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .top-cover {
    width: 100px;
    height: 150px;
    margin-right: 20px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .metadata-repeatable {
    &:not(:first-child) {
      margin-top: 4px;
    }
  }

  .metadata-abstract {
    &.expandable {
      &.collapsed {
        height: 110px;
        overflow: hidden;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 60px;
          background: linear-gradient(rgba(255, 255, 255, 0), white);
        }
      }

      &.expanded {
        padding-bottom: 16px;
      }
    }
  }

  .abstract-toggle {
    width: 100%;
    height: 10px;
    border-top: 1px solid #ccc;
    cursor: pointer;

    .abstract-toggle-indicator {
      position: absolute;
      top: -10px;
      left: calc(50% - 10px);
      width: 20px;
      height: 20px;
      background: white;
      border: 1px solid $main_light;
      border-radius: 50%;

      &::after {
        content: "";
        position: absolute;
        top: calc(50% - 2px);
        left: calc(50% - 4px);
        border-top: 6px solid $main_light;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }

      &.up::after {
        top: calc(50% - 4px);
        border-top: none;
        border-bottom: 6px solid $main_light;
      }
    }
  }

  .section-copies .copy {
    margin-left: -10px;
    width: calc(100% + 20px);

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .metadata-titlerelation {
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    margin-left: -10px;
    width: calc(100% + 20px);

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
</style>
