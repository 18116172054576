<template>
    <div class="reader-container" :class="[showReaderPanel? 'reader-panel-extended' : 'reader-panel-collapsed', mimetype? 'reader-container-' + mimetype : '']">
        <transition name="loading-fade">
            <div v-if="!loaded" id="reader-container-loading" class="reader-container-loading">
                <div>
                    <img src="/img/anim_searching_white.gif" style="max-width: 100%;" />
                </div>
            </div>
        </transition>

        <div class="reader-bar">
            <div v-if="!isMobile" class="reader-bar-searchbar">
              <xposi-readersearchbar></xposi-readersearchbar>
            </div>
            <xposi-navigationbar></xposi-navigationbar>
            <div v-if="!isMobile" class="reader-bar-settings">
                <xposi-zoombuttons style="display: inline-block;"></xposi-zoombuttons>
            </div>
        </div>
        <xposi-readerpanel :expanded="showReaderPanel"></xposi-readerpanel>
        <xposi-pdfviewer v-if="mimetype === 'pdf' && hasLicense"></xposi-pdfviewer>
        <xposi-epubviewer v-if="mimetype === 'epub' && hasLicense"></xposi-epubviewer>
    </div>
</template>

<script>
import NavigationBar from '@/components/reader/NavigationBar.vue'
import ZoomButtons from '@/components/reader/ZoomButtons.vue'
import ReaderPanel from '@/components/reader/ReaderPanel.vue'
import PDFViewer from '@/components/reader/PDFViewer.vue'
import EPUBViewer from '@/components/reader/EPUBViewer.vue'
import ReaderSearchBar from '@/components/reader/ReaderSearchBar.vue'

import ReaderStore from '@/store/ReaderStore'

import ScreenSizeMixin from '@/mixins/screensize'
import domparser from '@/mixins/domparser'

import { Events } from '@/events'

export default {
  data () {
    return {
      docKey: this.$route.params.docKey,
      hasLicense: false,
      loaded: false,
      licensePollingActive: false,
      licensePollingTimer: null,
      LICENSE_POLLING_INTERVAL_MS: 5 * 1000,
      SOFT_NOTICE_LIMIT: 30,
      modalsOpen: {
        extendNotice: false,
        expiredNotice: false
      },
      showReaderPanel: false
    }
  },
  computed: {
    mimetype () {
      return this.$store.getters['reader/getMimetype']
    },
    pubKey () {
      return this.$store.getters['reader/getPubKey']
    },
    license () {
      return this.$store.getters['reader/getLicense']
    }
  },
  methods: {
    initialize () {
      this.$store.dispatch('reader/APIGetMetadata', this.docKey, { root: true })
        .then(() => {
          this.setPageTitle()
          this.$store.dispatch('reader/APILicenseRequest', this.pubKey, { root: true })
            .then(() => {
              this.hasLicense = true
              this.$store.dispatch('reader/APIGetToc', this.docKey, { root: true })
                .then(() => {
                  this.loaded = true
                  Events.$emit('readerLoaded')
                })
                .catch(error => {
                  console.log(error)
                  this.loaded = true
                  Events.$emit('readerLoaded')
                })
            })
            .catch((error) => {
              if (error.response) {
                const status = error.response.status
                if (status === 403) {
                  const responseBody = error.response.data

                  const readingUsers = responseBody.users
                  let readingUsersList = null

                  if (readingUsers && readingUsers.length) {
                    readingUsersList = '<ul>'
                    for (let i = 0; i < readingUsers.length; i++) {
                      readingUsersList += `<li>${readingUsers[i]}</li>`
                    }
                    readingUsersList += '</ul>'
                  }

                  Events.$emit('modalOpen', {
                    type: 'error',
                    icon: 'nodoc.png',
                    title: this.$t('This document is already in use'),
                    body: readingUsersList
                      ? `<p>${this.$t('This document is currently opened by')}:</p>${readingUsersList}<p>${this.$t('Please try again when one of the open documents is closed')}`
                      : `<p>${this.$t('This document is already in use by a colleague. Try again later.')}</p>`,
                    actions: [
                      {
                        label: this.$t('Close document'),
                        class: 'input-button-primary',
                        action: () => {
                          this.navigateToHome()
                        }
                      }
                    ],
                    requireAction: true
                  })
                } else if (status === 404) {
                  Events.$emit('modalOpen', {
                    type: 'error',
                    icon: 'nodoc.png',
                    title: this.$t('Metadata not found'),
                    body: this.$t('The metadata could not be found for this document. This data is needed to initialize the reader.'),
                    requireAction: true
                  })
                }
              }
            })
        })
        .catch(error => {
          if (error.response) {
            Events.$emit('modalOpen', {
              type: 'error',
              icon: 'nodoc.png',
              title: this.$t('Metadata not found'),
              body: this.$t('The metadata could not be found for this document. This data is needed to initialize the reader.'),
              requireAction: true
            })
          }
        })
    },
    startLicensePolling () {
      if (this.licensePollingActive) return

      if (this.license && this.license.licenseKey) {
        this.licensePollingActive = true
        this.pollLicense()
      } else {
        this.stopLicensePolling()
      }
    },
    stopLicensePolling () {
      clearTimeout(this.licensePollingTimer)
      this.licensePollingTimer = null
      this.licensePollingActive = false
    },
    pollLicense () {
      this.$store.dispatch('reader/APILicenseExpiry', this.license.licenseKey, { root: true })
        .then(licenseActive => {
          if (licenseActive) {
            if (this.license.softExpiryTime < this.SOFT_NOTICE_LIMIT) this.extendLicenseNotice()

            if (!this.licensePollingActive) return
            this.licensePollingTimer = setTimeout(() => {
              this.pollLicense()
            }, this.LICENSE_POLLING_INTERVAL_MS)
          } else {
            this.expiredLicenseNotice()
          }
        })
        .catch((error) => {
          if (error.response) {
            const status = error.response.status

            if (status === 403) this.expiredLicenseNotice()
          }
        })
    },
    expiredLicenseNotice () {
      const _this = this

      if (this.modalsOpen.expiredNotice) return

      Events.$emit('modalClose')
      this.stopLicensePolling()

      this.modalsOpen.extendNotice = false
      this.modalsOpen.expiredNotice = true

      Events.$emit('modalOpen', {
        type: 'error',
        icon: 'license-expired.png',
        title: this.$t('Loan period expired'),
        body: this.$t('The loan period for this document has expired. Choose "request extention" to request an extention, or "close" to close this document.'),
        actions: [
          {
            label: this.$t('Close document'),
            action () {
              _this.navigateToHome()
            }
          },
          {
            label: this.$t('Request extention'),
            class: 'input-button-primary',
            action () {
              _this.reopenLicense()
            }
          }
        ],
        requireAction: true
      })
    },
    extendLicenseNotice () {
      const _this = this

      if (this.modalsOpen.extendNotice) return

      this.modalsOpen.extendNotice = true

      Events.$emit('modalOpen', {
        type: 'info',
        icon: 'license-expiry.png',
        title: this.$t('The loan period is about to expire'),
        body: this.$t('The loan period is about to expire due to inactivity. Do you want to continue using this document or do you want to close it?'),
        actions: [
          {
            label: this.$t('Close document'),
            action () {
              _this.navigateToHome()
            }
          },
          {
            label: this.$t('Continue using this document'),
            class: 'input-button-primary',
            action () {
              _this.extendLicense()
            }
          }
        ],
        requireAction: true
      })
    },
    releaseLicense () {
      if (this.license) {
        return this.$store.dispatch('reader/APILicenseRelease', this.license.licenseKey, { root: true })
      }
      return Promise.resolve()
    },
    reopenLicense () {
      const _this = this

      this.$store.dispatch('reader/APILicenseRequest', this.pubKey, { root: true })
        .then(() => {
          Events.$emit('modalClose')
          _this.startLicensePolling()
          this.modalsOpen.expiredNotice = false
        })
        .catch(error => {
          console.log(error)

          Events.$emit('modalOpen', {
            type: 'error',
            icon: 'nodoc.png',
            title: this.$t('This document is already in use by a colleague. Try again later.'),
            body: this.$t('Dit document is op dit moment al in gebruik door een collega. Probeer het later opnieuw.'),
            actions: [
              {
                label: this.$t('Close document'),
                class: 'input-button-primary',
                action: () => {
                  this.navigateToHome()
                }
              }
            ],
            requireAction: true
          })
        })
    },
    extendLicense () {
      const _this = this

      this.$store.dispatch('reader/APILicenseExtend', this.license.licenseKey, { root: true })
        .then(licenseActive => {
          if (licenseActive) {
            this.modalsOpen.expiredNotice = false
            Events.$emit('modalClose')
          } else {
            _this.expiredLicenseNotice()
          }
        })
        .catch(error => {
          console.log(error)
          _this.expiredLicenseNotice()
        })
    },
    navigateToHome () {
      this.$router.push({ name: 'results' })
    },
    setPageTitle () {
      const title = this.$store.getters['reader/getAppearanceTitle']

      if (title && title !== '') document.title = window.globals.VUE_APP_TITLE + ': ' + this.parseDOM(title)
    },
    expandReaderPanel () {
      this.showReaderPanel = true
    },
    collapseReaderPanel () {
      this.showReaderPanel = false
    },
    attachReaderStore () {
      this.$store.registerModule('reader', ReaderStore())
      this.$store.commit('reader/storeDocKey', this.docKey)
    }
  },
  components: {
    'xposi-navigationbar': NavigationBar,
    'xposi-readerpanel': ReaderPanel,
    'xposi-pdfviewer': PDFViewer,
    'xposi-zoombuttons': ZoomButtons,
    'xposi-epubviewer': EPUBViewer,
    'xposi-readersearchbar': ReaderSearchBar
  },
  created () {
    this.attachReaderStore()
  },
  mounted () {
    Events.$on('readerLoaded', this.startLicensePolling)
    Events.$on('readerLicenseExpired', this.expiredLicenseNotice)
    Events.$on('readerPanelExpand', this.expandReaderPanel)
    Events.$on('readerPanelCollapse', this.collapseReaderPanel)

    this.initialize()
  },
  async beforeRouteLeave (to, from, next) {
    try {
      Events.$emit('modalClose')
      this.stopLicensePolling()
      await this.releaseLicense()
      next()
    } catch {
      next()
    }
  },
  unmounted () {
    this.$store.unregisterModule('reader')
    Events.$off('readerLoaded', this.startLicensePolling)
    Events.$off('readerLicenseExpired', this.expiredLicenseNotice)
    Events.$off('readerPanelExpand', this.expandReaderPanel)
    Events.$off('readerPanelCollapse', this.collapseReaderPanel)
  },
  mixins: [ScreenSizeMixin, domparser]
}
</script>
