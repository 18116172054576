<template>
  <div class="book-catalogue-item" :class="{ 'book-catalogue-item-selected': isSelected }" @click="selectCatalogueItem()">
    <div v-if="isNew" :title="$t('item.new.tooltip', { daysMarkedAsNew })" class="book-catalogue-item-new"></div>
    <div class="book-catalogue-item-info">
      <div v-if="authorDisplay" class="book-catalogue-item-authors" :title="authorDisplay">{{ authorDisplay }}</div>
      <div v-if="book.title" class="book-catalogue-item-title" v-html="parseDOM(book.title)" :title="parseDOM(book.title)"></div>
      <div v-if="book.subtitle" class="book-catalogue-item-subtitle" :title="book.subtitle">{{ book.subtitle }}</div>
      <div v-if="publicationDisplay" class="book-catalogue-item-publication" :title="publicationDisplay">{{ publicationDisplay }}</div>
      <div v-if="book.priceInCents" class="book-catalogue-item-price">
        {{ $filters.currency(book.priceInCents) }}
        <span class="book-catalogue-item-price-tax">{{ $t('common.excluding-tax') }}</span>
      </div>
      <div class="book-catalogue-item-actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <LazyLoadingImg ref="cover" :url="getCoverUrlForBook(book)" class="book-catalogue-item-cover">
      <template v-slot:status-failed>
        <img class="book-catalogue-cover-placeholder" src="/img/cover_placeholder.png" />
      </template>
    </LazyLoadingImg>
    <div class="clear"></div>
  </div>
</template>

<script>
import LazyLoadingImg from '@/components/LazyLoadingImg.vue'
import bookCoverUrlMixin from '@/mixins/bookCoverUrl'
import domparserMixin from '@/mixins/domparser'
import viewPortMixin from '@/mixins/viewPort'

import {
  parse as parseDate,
  differenceInDays
} from 'date-fns'

export default {
  data: function () {
    return {
      daysMarkedAsNew: 14
    }
  },
  props: {
    book: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    publicationDisplay () {
      let displayString = ''
      const { publisher, yearOfPublication, edition } = this.book

      if (publisher) displayString += publisher + ' '
      if (yearOfPublication) displayString += yearOfPublication + ' '
      if (edition) {
        const wrap = (publisher || yearOfPublication)
        if (wrap) displayString += '('
        displayString += edition
        if (wrap) displayString += ')'
      }

      return displayString
    },
    authorDisplay () {
      let displayString = ''

      if (!this.book.authors) return displayString

      for (let i = 0; i < this.book.authors.length; i++) {
        if (i > 0) displayString += '; '
        displayString += this.book.authors[i]
      }

      return displayString
    },
    isNew () {
      if (!this.book.created && !this.book.licenseCreated) return false

      const createdDateString = this.book.created || this.book.licenseCreated
      const bookCreatedDate = parseDate(createdDateString, 'yyyy-MM-dd HH:mm:ss', new Date())
      const now = new Date()

      return differenceInDays(now, bookCreatedDate) < this.daysMarkedAsNew
    }
  },
  methods: {
    loadBookCover () {
      this.$refs.cover.loadImage()
    },
    selectCatalogueItem () {
      this.$emit('catalogueItemSelected', this.book)
    },
    scrollIntoView () {
      this.$el.scrollIntoView()
    }
  },
  components: {
    LazyLoadingImg
  },
  mixins: [
    bookCoverUrlMixin,
    domparserMixin,
    viewPortMixin
  ],
  emits: ['catalogueItemSelected']
}
</script>

<style>
</style>
