<template>
  <div class="page-container">

    <div class="page-navigation-container">
      <div class="shop-library-navigation-search-container">
        <input
            type="search"
            class="input-text flex-grow-1"
            v-model="inputSearchQuery"
            @keypress.enter="performSearch()"
            :placeholder="$t('shop.library.search.placeholder')" />
        <button @click="performSearch()" class="input-button input-button-primary"><img src="/img/icons/search_white.png" /></button>
      </div>
    </div>

    <div class="page-content space-for-navigation-bar">
      <BookCatalogue
          :class="selectedBook ? 'book-catalogue-mode-detail' : ''"
          :totalAmountOfBooks="totalAmountOfBooks"
          :isLoadingFirstPage="isLoadingFirstPage"
          :isLoadingAdditionalPages="isLoadingAdditionalPages"
          :errorLoading="errorLoading"
          @debouncedScroll="handleDebouncedScrollEvent()">

        <template v-slot:catalogueItems>
          <BookCatalogueItem ref="catalogueItems"
              v-for="book in books" :key="book.pubKey"
              :book="book"
              :isSelected="book === selectedBook"
              @catalogueItemSelected="SELECT_BOOK($event)">
            <template v-slot:actions>
              <button v-if="!isBookAlreadyInShoppingBasket(book)" class="input-button input-button-outlined" @click.stop="ADD_BOOK_TO_BASKET({ book })">{{ $t('shop.library.items.add-to-basket') }}</button>
              <button v-else class="input-button input-button-outlined" disabled>{{ $t('shop.library.items.already-in-basket') }}</button>
            </template>
          </BookCatalogueItem>
        </template>

        <template v-slot:listLazyLoader>
          <ListLazyLoader ref="listLazyLoader"
              :isLoading="isLoadingAdditionalPages"
              :thereIsMoreToLoad="books.length < totalAmountOfBooks"
              @triggerLazyLoad="loadBooks" />
        </template>

      </BookCatalogue>
    </div>

    <BookCatalogueDetailPanel
        :book="selectedBook"
        @close="DESELECT_BOOK">
      <template v-slot:actions>
        <button v-if="!isBookAlreadyInShoppingBasket(selectedBook)" class="input-button input-button-outlined" @click.stop="ADD_BOOK_TO_BASKET({ book: selectedBook })">{{ $t('shop.library.items.add-to-basket') }}</button>
        <button v-else class="input-button input-button-outlined" disabled>{{ $t('shop.library.items.already-in-basket') }}</button>
      </template>
    </BookCatalogueDetailPanel>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BookCatalogue from '@/components/book-catalogue/BookCatalogue.vue'
import BookCatalogueItem from '@/components/book-catalogue/BookCatalogueItem.vue'
import BookCatalogueDetailPanel from '@/components/book-catalogue/BookCatalogueDetailPanel.vue'
import ListLazyLoader from '@/components/ListLazyLoader.vue'

export default {
  data () {
    return {
      inputSearchQuery: null
    }
  },
  computed: {
    ...mapState({
      searchQuery: state => state.shop.library.search.query,
      books: state => state.shop.library.books.items,
      totalAmountOfBooks: state => state.shop.library.books.totalAmount,
      selectedBook: state => state.shop.library.books.selectedItem,
      isLoading: state => state.shop.library.books.isLoading,
      errorLoading: state => state.shop.library.books.errorLoading,
      booksInBasket: state => state.shop.basket.books
    }),
    isLoadingFirstPage () {
      return !!(this.isLoading && !this.books.length)
    },
    isLoadingAdditionalPages () {
      return !!(this.isLoading && this.books.length)
    },
    catalogueItemElements () {
      return this.$refs.catalogueItems || []
    }
  },
  methods: {
    ...mapActions('shop/library', [
      'RESET_BOOKS',
      'LOAD_BOOKS',
      'SELECT_BOOK',
      'DESELECT_BOOK'
    ]),
    ...mapActions('shop/basket', [
      'ADD_BOOK_TO_BASKET'
    ]),
    performSearch () {
      this.RESET_BOOKS()
      this.loadBooks()
    },
    handleDebouncedScrollEvent () {
      this.loadImagesForBooksInViewPort()
      this.$refs.listLazyLoader.onScroll()
    },
    loadBooks () {
      this.LOAD_BOOKS({
        query: this.inputSearchQuery
      }).then(() => {
        this.loadImagesForBooksInViewPort()
      })
    },
    loadImagesForBooksInViewPort () {
      for (const catalogueItemElement of this.catalogueItemElements) {
        if (catalogueItemElement.isInViewPort()) {
          catalogueItemElement.loadBookCover()
        }
      }
    },
    isBookAlreadyInShoppingBasket (book) {
      return !!(this.booksInBasket.find(b => b.pubKey === book.pubKey))
    }
  },
  created () {
    this.inputSearchQuery = this.searchQuery
  },
  mounted () {
    this.RESET_BOOKS()
    this.loadBooks()
  },
  components: {
    BookCatalogue,
    BookCatalogueItem,
    BookCatalogueDetailPanel,
    ListLazyLoader
  }
}
</script>

<style>
</style>
