import { createRouter, createWebHistory } from 'vue-router'
import Reader from '@/views/Reader'
import DirectDocumentLoader from '@/views/DirectDocumentLoader'
// import Library from '@/views/Library'
import PublicationLoader from '@/views/PublicationLoader'
import ShopBasket from '@/views/ShopBasket'
import ShopCheckout from '@/views/ShopCheckout'
import ShopLibrary from '@/views/ShopLibrary'
import ShopSuccess from '@/views/ShopSuccess'
import Home from '@/views/Home'
import Results from '@/views/Results'
import AdminArea from '@/views/AdminArea'
import Bookshelf from '@/views/Bookshelf'
import BookshelfContent from '@/views/BookshelfContent'
import { routeBeforeEachHandler, prepareSSOUrl, createSearchQueryParam } from '@/utilities/routerHelper'

import Roles from '@/constants/roles'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { protected: true }
  },
  {
    path: '/search',
    name: 'results',
    component: Results,
    meta: { protected: true }
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminArea,
    meta: { protected: true, role: Roles.ADMIN }
  },
  {
    path: '/shop',
    redirect: () => ({ name: 'shop_library' })
  },
  {
    path: '/shop/basket',
    name: 'shop_basket',
    component: ShopBasket,
    meta: { protected: true }
  },
  {
    path: '/shop/checkout',
    name: 'shop_checkout',
    component: ShopCheckout,
    meta: { protected: true }
  },
  {
    path: '/shop/library',
    name: 'shop_library',
    component: ShopLibrary,
    meta: { protected: true }
  },
  {
    path: '/shop/success',
    name: 'shop_success',
    component: ShopSuccess,
    meta: { protected: true }
  },
  {
    path: '/reader/:docKey',
    name: 'reader',
    component: Reader,
    meta: { protected: true }
  },
  {
    path: '/document/open',
    name: 'directdocumentloader',
    component: DirectDocumentLoader,
    meta: { protected: false }
  },
  {
    path: '/document/:docKey',
    redirect: to => ({ name: 'reader', params: { docKey: to.params.docKey } })
  },
  {
    path: '/sso',
    beforeEnter () {
      location.href = `${prepareSSOUrl()}&forcesso=1`
    }
  },
  {
    path: '/bookshelf',
    name: 'bookshelf',
    component: Bookshelf,
    meta: { protected: true },
    children: [
      {
        path: ':id(\\d+)',
        name: 'bookshelfContent',
        component: BookshelfContent
      }
    ]
  },
  {
    path: '/:pubKey(pub-.*)',
    name: 'publicationloader',
    component: PublicationLoader,
    meta: { protected: true }
  },
  {
    path: '/:catchAll(.*)*',
    redirect: to => {
      const queryParams = {}
      if (to.query.accessToken) queryParams.accessToken = to.query.accessToken
      if (to.query.refreshToken) queryParams.refreshToken = to.query.refreshToken

      // support for configObject url; when openDocument query param is present, start search on value instead of fallback navigation to home.
      if (to.query.openDocument) {
        queryParams.query = JSON.stringify(createSearchQueryParam(to.query.openDocument))
        return { name: 'results', query: queryParams }
      }

      return { name: 'home', query: queryParams }
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(routeBeforeEachHandler)

export default router
