import VueCookies from 'vue-cookies'
import store from '@/store'
import i18n from '@/i18n'
import { getInitialLanguage } from '@/utilities/languagehelper'
import { roleIncludes } from './roleHelper'
import axiosAPI from '@/axiosAPI'

async function routeBeforeEachHandler (to, from, next) {
  setDefaultPageTitle()

  if (isPublicLibrary()) {
    const { data: tokens } = await axiosAPI.get(`${window.globals.VUE_APP_SSOURL}/application/${window.globals.VUE_APP_SSOAPPID}/publictoken`)

    VueCookies.set('accessToken', tokens.accessToken)
    VueCookies.set('refreshToken', tokens.refreshToken)

    store.commit('setPublicLibrary', true)
  }

  if (routeHasAccesAndRefreshToken(to)) {
    parseAccessAndRefreshTokensFromQuery(to)
    return next({ name: to.name, params: to.params, query: to.query })
  }

  copyTokensFromCookiesToStore()

  if (to.meta.protected && !isAuthenticated()) {
    redirectToLoginForRouteTo(to)
    return next(false)
  }

  await initialise()

  if (to.meta.role && !roleIncludes(store.state.role, to.meta.role)) {
    // User does not have the correct role for this route, redirect to home
    return next({ name: 'home' })
  }

  next()
}

function redirectToLoginForRouteTo (to) {
  let SSO_URL = prepareSSOUrl()

  if (to.name === 'publicationloader' && to.params.pubKey) SSO_URL += '&pubKey=' + to.params.pubKey
  if (to.name === 'reader' && to.params.docKey) SSO_URL += '&docKey=' + to.params.docKey

  window.location = SSO_URL
}

function copyTokensFromCookiesToStore () {
  store.state.auth.accessToken = VueCookies.isKey('accessToken') ? VueCookies.get('accessToken') : null
  store.state.auth.refreshToken = VueCookies.isKey('refreshToken') ? VueCookies.get('refreshToken') : null
}

function isAuthenticated () {
  return !(!VueCookies.isKey('accessToken') || !VueCookies.isKey('refreshToken'))
}

function prepareSSOUrl () {
  return `${window.globals.VUE_APP_SSOURL}/application/${window.globals.VUE_APP_SSOAPPID}?appName=${window.globals.VUE_APP_SSOAPPNAME}&client=${window.globals.VUE_APP_SSOCLIENT}&secret=${window.globals.VUE_APP_SSOSECRET}`
}

function routeHasAccesAndRefreshToken (to) {
  return !(!to.query.accessToken || !to.query.refreshToken)
}

function parseAccessAndRefreshTokensFromQuery (to) {
  VueCookies.set('accessToken', to.query.accessToken)
  VueCookies.set('refreshToken', to.query.refreshToken)
  delete to.query.accessToken
  delete to.query.refreshToken
}

function setDefaultPageTitle () {
  document.title = window.globals.VUE_APP_TITLE
}

async function initialise () {
  if (store.state.initialised) return

  await store.dispatch('initialise')

  const availableLanguages = i18n.global.availableLocales
  store.commit('setLanguages', availableLanguages)

  let language = getInitialLanguage()
  if (!availableLanguages.includes(language)) {
    language = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'
  }

  i18n.global.locale.value = language
  store.dispatch('changeLanguage', { language, i18n, store: false })

  store.dispatch('searchStore/currentSearch/newSearch')

  store.state.initialised = true
}

function isPublicLibrary () {
  const currentHostname = location.hostname
  const protectedHostname = (new URL(window.globals.VUE_APP_HOST)).hostname
  return (currentHostname !== protectedHostname)
}

function createSearchQueryParam (query) {
  return {
    query: `"${query}"`,
    queryType: 0,
    module: 2
  }
}

export {
  routeBeforeEachHandler,
  redirectToLoginForRouteTo,
  copyTokensFromCookiesToStore,
  isAuthenticated,
  prepareSSOUrl,
  parseAccessAndRefreshTokensFromQuery,
  routeHasAccesAndRefreshToken,
  setDefaultPageTitle,
  createSearchQueryParam
}
